import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { ForgotPassword, NotFound, ResetPassword } from "@sal/portal";
import { lazy, Suspense } from "react";
import { HashRouter, Outlet, Routes, Route } from "react-router-dom";
import BasePage from "./pages/Layout/BasePage";
import { LoginPage } from "./pages/LoginPage";
import { routePermissionMapping } from "./appsettings";
import { PortalAuthenticatedRoute } from "@sal/onevent-portal";

const HomePage = lazy(() => import("./pages/HomePage"));
const ConsignmentDashboardPage = lazy(() => import("./pages/ConsignmentDashboardPage"));
const FreightBookingPage = lazy(() => import("./pages/FreightBookingPage"));
const ContainerBookingPage = lazy(() => import("./pages/ContainerBookingPage"));
const FreightTrackerPage = lazy(() => import("./pages/FreightTrackerPage"));
const RatesPage = lazy(() => import("./pages/RatesPage"));

const ContainerConsignmentTrackerPage = lazy(() => import("./pages/ContainerConsignmentTrackerPage"));
const TrackPage = lazy(() => import("./pages/TrackPage"));
const ConsignmentListPage = lazy(() => import("./pages/ConsignmentListPage"));
const NetworkHealthPage = lazy(() => import("./pages/NetworkHealthPage"));
const AnalyticsPage = lazy(() => import("./pages/AnalyticsPage"));
const CustomersPage = lazy(() => import("./pages/CustomersPage"));

const UserDashboardPage = lazy(() => import("./pages/UserDashboardPage"));
const UserDetailPage = lazy(() => import("./pages/UserDetailPage"));
const UserNewPage = lazy(() => import("./pages/UserNewPage"));
const MyAccountPage = lazy(() => import("./pages/MyAccountPage"));

const {
	dashboard,
	freightBooking,
	containerBooking,
	freightDetail,
	containerDetail,
	track,
	rates,
	consignmentList,
	networkHealth,
	users,
	analytics,
	customers
} = routePermissionMapping;

export default function Router() {
	return (
		<HashRouter>
			<Routes>
				<Route element={<PortalAuthenticatedRoute />}>
					<Route element={<BasePage />}>
						<Route
							element={
								<Suspense
									fallback={
										<Backdrop invisible open>
											<CircularProgress />
										</Backdrop>
									}
								>
									<Outlet />
								</Suspense>
							}
						>
							<Route path="/" element={<HomePage />} />
							<Route
								path={dashboard.path}
								element={
									<PortalAuthenticatedRoute
										securityContext={dashboard.securityContext}
										securityContextPermission={dashboard.securityContextPermission}
									>
										<ConsignmentDashboardPage />
									</PortalAuthenticatedRoute>
								}
							/>
							<Route
								path={freightBooking.path}
								element={
									<PortalAuthenticatedRoute
										securityContext={freightBooking.securityContext}
										securityContextPermission={freightBooking.securityContextPermission}
									>
										<FreightBookingPage />
									</PortalAuthenticatedRoute>
								}
							/>
							<Route
								path={freightBooking.path + "/:id"}
								element={
									<PortalAuthenticatedRoute
										securityContext={freightBooking.securityContext}
										securityContextPermission={"Read"}
									>
										<FreightBookingPage />
									</PortalAuthenticatedRoute>
								}
							/>
							<Route
								path={containerBooking.path}
								element={
									<PortalAuthenticatedRoute
										securityContext={containerBooking.securityContext}
										securityContextPermission={containerBooking.securityContextPermission}
									>
										<ContainerBookingPage />
									</PortalAuthenticatedRoute>
								}
							/>
							<Route
								path={containerBooking.path + "/:id"}
								element={
									<PortalAuthenticatedRoute
										securityContext={containerBooking.securityContext}
										securityContextPermission={"Read"}
									>
										<ContainerBookingPage />
									</PortalAuthenticatedRoute>
								}
							/>
							<Route
								path={freightDetail.path}
								element={
									<PortalAuthenticatedRoute
										securityContext={freightDetail.securityContext}
										securityContextPermission={freightDetail.securityContextPermission}
									>
										<FreightTrackerPage />
									</PortalAuthenticatedRoute>
								}
							/>
							<Route
								path={containerDetail.path}
								element={
									<PortalAuthenticatedRoute
										securityContext={containerDetail.securityContext}
										securityContextPermission={containerDetail.securityContextPermission}
									>
										<ContainerConsignmentTrackerPage />
									</PortalAuthenticatedRoute>
								}
							/>
							<Route
								path={track.path}
								element={
									<PortalAuthenticatedRoute
										securityContext={track.securityContext}
										securityContextPermission={track.securityContextPermission}
									>
										<TrackPage />
									</PortalAuthenticatedRoute>
								}
							/>
							<Route
								path={rates.path}
								element={
									<PortalAuthenticatedRoute
										securityContext={rates.securityContext}
										securityContextPermission={rates.securityContextPermission}
									>
										<RatesPage />
									</PortalAuthenticatedRoute>
								}
							/>
							<Route
								path={consignmentList.path}
								element={
									<PortalAuthenticatedRoute
										securityContext={consignmentList.securityContext}
										securityContextPermission={consignmentList.securityContextPermission}
									>
										<ConsignmentListPage />
									</PortalAuthenticatedRoute>
								}
							/>
							<Route
								path={networkHealth.path}
								element={
									<PortalAuthenticatedRoute
										securityContext={networkHealth.securityContext}
										securityContextPermission={networkHealth.securityContextPermission}
									>
										<NetworkHealthPage />
									</PortalAuthenticatedRoute>
								}
							/>
							<Route
								path={analytics.path}
								element={
									<PortalAuthenticatedRoute
										securityContext={analytics.securityContext}
										securityContextPermission={analytics.securityContextPermission}
									>
										<AnalyticsPage />
									</PortalAuthenticatedRoute>
								}
							/>
							<Route
								path={customers.path}
								element={
									<PortalAuthenticatedRoute
										securityContext={customers.securityContext}
										securityContextPermission={customers.securityContextPermission}
									>
										<CustomersPage />
									</PortalAuthenticatedRoute>
								}
							/>

							{/* No permissions needed */}
							<Route path="/MyAccount" element={<MyAccountPage />} />

							<Route
								path={users.path}
								element={
									<PortalAuthenticatedRoute
										securityContext={users.securityContext}
										securityContextPermission={users.securityContextPermission}
									/>
								}
							>
								<Route index element={<UserDashboardPage />} />
								<Route path=":id" element={<UserDetailPage />} />
								<Route
									path="new"
									element={
										<PortalAuthenticatedRoute
											securityContext={users.securityContext}
											securityContextPermission="Write"
										>
											<UserNewPage />
										</PortalAuthenticatedRoute>
									}
								/>
							</Route>
						</Route>
					</Route>
				</Route>
				{/* Portal Framework */}
				<Route path="/Login" element={<LoginPage />} />
				<Route path="/ForgotPassword" element={<ForgotPassword />} />
				<Route path="/ResetPassword/:passwordResetToken" element={<ResetPassword />} />
				<Route path="/NotFound" element={<NotFound />} />

				<Route path="*" element={<NotFound />} />
				{/* Portal Framework */}
			</Routes>
		</HashRouter>
	);
}
