import { createRoot } from "react-dom/client";
import { PortalFramework } from "@sal/portal";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { LicenseInfo } from "@mui/x-date-pickers-pro";
import AppSettings from "./appsettings";
import Theme from "./theme";
import Router from "./Router";
import "@fontsource/inter";
import "@fontsource/montserrat";
import "@fontsource/montserrat/700.css";
import "./locale";
import { PortalApplicationContext } from "@sal/onevent-portal";
import App from "./App";

LicenseInfo.setLicenseKey(process.env.LICENSE_MUI!);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: false,
			refetchOnWindowFocus: false
		},
		mutations: {
			retry: false
		}
	}
});

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
	<PortalFramework settings={AppSettings.PortalFramework}>
		<ThemeProvider theme={Theme}>
			<CssBaseline />
			<QueryClientProvider client={queryClient}>
				<PortalApplicationContext>
					<App>
						<Router />
					</App>
				</PortalApplicationContext>
			</QueryClientProvider>
		</ThemeProvider>
	</PortalFramework>
);
