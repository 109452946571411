import { useAuth } from "@sal/authentication.react";
import { HttpService } from "@sal/onevent-portal";

function App({ children }: React.PropsWithChildren) {
	const auth = useAuth();

	HttpService.configure({ unAuthorizedResponseHandler: () => auth.logout()});

	return children;
}

export default App;